import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { Breadcrumb } from "rsuite";
import useFetch from "../../hooks/useFetch";
import Navbar from "../../components/navbar/Navbar";


import EnergyMeter from "./EnergyMeter/EnergyMeter";
// import HeatMeter from './HeatMeter/HeatMeter'

import "./Meter.scss";

const Meter = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [meter, setMeter] = useState({});
  const [lastSeenDate, setLastSeenDate] = useState({});
  const { data, loading, error, reFetch } = useFetch(`/meters/${id}`);

  useEffect(() => {
    setMeter(data);
  }, [data]);

  const renderMeter = () => {
    // if (meter.MeterType.type === "EnergyMeter") {
    return <EnergyMeter meter={meter} />;
    // }
    // else if (meter.MeterType.type === "HeatMeter") {
    //   return <HeatMeter meter={meter} />
    // }
  };
  console.log(data)

  return (
    <>
      <Navbar />
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item className="breadcrumb-item" href="/meters">
          Meters
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item">
          {meter?.Section?.Location?.Customer?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item">
          {meter?.Section?.Location?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item" href={`/sections/${meter?.sectionId}`}>
          {meter?.Section?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item" active>
          {meter?.name || meter?.identifier}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="meter">
        <div className="meterContainer">
          {meter.id && renderMeter()}
        </div>
      </div>
    </>
  );
};

export default Meter;
