import Navbar from "../../components/navbar/Navbar"
// import Widget from "../../components/widget/Widget"
// import Featured from "../../components/featured/Featured"

import SpotPriceChart from "../../components/SpotPriceChart/SpotPriceChart"

import "./dashboard.scss"

const Dashboard = () => {
  
  return (
    <>
      <Navbar />
      <div className="dashboard">


        <div className="spotprice">
          <SpotPriceChart title="Nordpool Spot Price (SE3)" aspect={3/1}/>
        </div>


        {/* <div className="meters">
          <MeterTable />
        </div> */}

      </div>
    </>
  )
}

export default Dashboard