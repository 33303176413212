import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import "./mytable.scss"

const MyTable = ({columns, rows}) => {

  return (
  <TableContainer component={Paper} className="table">
    <Table sx={{}}>
      <TableHead>
        <TableRow>
          {columns?.map(col => (
              <TableCell key={col.headerName} className="tableHeader" align={col.align}>
                {col.headerName}
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map(row => (
          <TableRow key={row.id}>
            {columns?.map(col => (
              <TableCell key={col.headerName} className="tableCell" align={col.align}>
                {col.renderCell 
                  ? col.renderCell(row)
                  : row[col.field]
                }
              </TableCell>
            ))}
          </TableRow>
        ))} 
      </TableBody>
    </Table>
  </TableContainer>
  )
}

export default MyTable