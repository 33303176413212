import { useState, useEffect } from 'react'
import useFetch from "../../hooks/useFetch"
import Navbar from "../../components/navbar/Navbar"
import MeterTable from "../../components/metertable/MeterTable"
import './MeterList.scss'

/*

Dashboard - Aktuell mätarställning
==================================

Section.name || Meter.name || Meter.identifier
Mätarställning
Förbrukning 
Nuvarande effekt
(Online/Offline)


Mätare (Grupperat per location)
======
Identifier
Name
Model
Vendor

List one table per location

getMeterDataByLocation

*/

const MeterList = () => {

  const [locations, setLocations] = useState([]);
  const {data, loading, error } = useFetch(`/locations`)

  useEffect(() => {
    setLocations(data)
  }, [data])

  return (
    <main className="meterList">
      <Navbar />
      <div className="meterListWrapper">
        {!loading && (
          <>
          {locations.map((l) => (
            <div key={l.id} className="locationTable">
              <h2>{l.name}</h2>
              <MeterTable locationId={l.id} />
            </div>
          ))}
        </>
        )}

      </div>
    </main>
  )
}

export default MeterList