import { useState, useEffect } from 'react'
import useAxiosPrivate from "./useAxiosPrivate"
import { useNavigate, useLocation } from "react-router-dom";

const useFetch = (url) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await axiosPrivate.get(url, {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                })
                setData(res.data)
            } 
            catch (err) {
                if (err.response.status === 401 || err.response.status === 403) {
                    // redirect to login if unauthorized
                    navigate('/login', { state: { from: location, unauthorized: true }, replace: true });
                }
                else {
                    setError(err)
                }
            }
            setLoading(false)
        }
        fetchData()
    }, [url])

    const reFetch = async () => {
        setLoading(true)
        try {
            const res = await axiosPrivate.get(url)
            setData(res.data)
        }
        catch (err) {
            setError(err)
        }
        setLoading(false)
    }

    return {data, loading, error, reFetch}
}

export default useFetch