import { createContext, useReducer, useEffect, useState } from "react";
import AuthReducer from "./AuthReducer";

export const AuthContext = createContext();

export const getSession = () => {
  // return localStorage.getItem('session');
  return JSON.parse(localStorage.getItem('session'));
};

export const setSessionInLocalStorage = (token) => {
  localStorage.setItem('session', JSON.stringify(token))
  return true
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(getSession() || '');

  useEffect(() => {
    setSessionInLocalStorage(auth)
  }, [auth]);
  
  return (
    <AuthContext.Provider value={{ 
      auth,
      setAuth,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext