
import { useState, useEffect } from 'react'

// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import format from 'date-fns/format'

import useFetch from "../../hooks/useFetch"
import "./spotpricechart.scss"

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    ReferenceLine,
    XAxis,
    YAxis,
    Cell,
    Tooltip,
    Legend,
    LabelList
} from "recharts";

const now = new Date()
const from_default = new Date(now.setDate(now.getDate()-2))
const to_default = new Date()

// todo: add support to get average values on bigger intervals

const SpotPriceChart = ({meter, aspect, title}) => {

  const [from, setFrom] = useState(from_default);
  const [to, setTo] = useState(to_default);

  const [chartData, setChartData] = useState([]);
  const { data, loading, error, reFetch } = useFetch(`/spotprice?from=${format(from, 'yyyy-MM-dd HH:mm')}&to=${format(to, 'yyyy-MM-dd HH:mm')}&area=SE3`)

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map(i => i.power));
    const dataMin = Math.min(...data.map(i => i.power));
    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };

  useEffect(() => {
    const pricesPerKwh = data.map((v) => {
      v.price /= 10.0
      return v
    })    
    setChartData(pricesPerKwh)
  }, [data]);

  // const off = gradientOffset();

  return (
    <div className="chart">
    <div className="top">
        <div className="title">{title}</div>
    </div>

    <ResponsiveContainer width="100%" aspect={aspect}>
        <BarChart
            data={chartData}
            margin={{ top: 10, right: 0, left: 25, bottom: 0 }}
        >
            {/* <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="#3070aa"  />
                <stop offset={off} stopColor="green"  />
                </linearGradient>
            </defs> */}
            <XAxis 
                dataKey="time" 
                // fontFamily="sans-serif" 
                tickSize dy="5" 
                tickFormatter={t => format(new Date(t), 'dd/MM HH:mm')}
                // domain={["dataMin", "dataMax"]}
                // type={"number"}
                // domain={['auto', 'auto']}
                // tickFormatter={dateFormatter}
                />
            <YAxis label={{ value: 'cent/kWh', angle: -90, position: 'left' }} />
            <Tooltip 
                labelFormatter={t => format(new Date(t), 'dd/MM HH:mm')}
                formatter={(value, name) => value.toFixed(3) + ' cent'}
                // cursor={{ fill: "transparent" }}
            />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <ReferenceLine y={0} stroke="lightgrey" strokeDasharray="3 3" />
            <Bar
                dataKey="price"
                // connectNulls={false}
                fill="green"
                // fill="url(#gradient)"
                // dot={false}
                // activeDot={false}
            >
                {data.length < 25 && (
                <LabelList dataKey="import" position="insideTop" />
                )}
                {/* {data.map((entry, index) => (
                <Cell fill={data[index].color} />
                ))} */}
            </Bar>
            </BarChart>
        </ResponsiveContainer>
    </div>
  )
}

export default SpotPriceChart