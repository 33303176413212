import { useState, useEffect, PureComponent } from 'react'
import Button from '@mui/material/Button';
import format from 'date-fns/format'

import { DateRangePicker, DatePicker, Dropdown } from 'rsuite';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMonths from 'date-fns/differenceInMonths';


import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';

import 'rsuite/dist/rsuite.min.css';

import "./EnergyChart.scss"

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList,
    Text,
} from "recharts";

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import MeterList from '../../pages/MeterList/MeterList';

const { combine, allowedMaxDays, afterToday, after } = DateRangePicker;

const intervalOptions = [ "hour", "day", "month", "year" ];

const predefinedRanges = [
  {
    label: 'Today',
    // value: [new Date(), new Date()],
    value: [startOfDay(new Date()), endOfDay(new Date())],
    placement: 'left'
  },
  // {
  //   label: 'Yesterday',
  //   value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  //   placement: 'left'
  // },
  // {
  //   label: 'This week',
  //   value: [startOfWeek(new Date()), endOfWeek(new Date())],
  //   placement: 'left'
  // },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  // {
  //   label: 'All time',
  //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
  //   placement: 'left'
  // },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 1 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 1 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 1 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 1 }), 7)
      ];
    },
    appearance: 'default'
  }
];


// const renderLegend = (props) => {
//   const {payload} = props;
//   return (
//     <ul>
//       {
//         payload.map((entry, index) => (
//           <li key={`item-${index}`}>{entry.value} TEST</li>
//         ))
//       }
//     </ul>
//   );

// };

// class CustomizedLabel extends PureComponent {
//   render() {
//     const { x, y, stroke, value } = this.props;
//     return (
//       <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//         {value}
//       </text>
//     );
//   }
// }


const now = new Date()
const defaultDateInterval = "day"
const defaultDateRange = [
  subDays(new Date(), 29),
  new Date()
]
// new Date(now.setDate(now.getDate()-30)),
defaultDateRange[0].setHours(0, 0, 0);
defaultDateRange[1].setHours(0, 0, 0);


const EnergyChart = ({meter, aspect, title}) => {

  const axiosPrivate = useAxiosPrivate()

  const [interval, setInterval] = useState(defaultDateInterval);
  const [dateRange, setDateRange] = useState(null)
  const [chartData, setChartData] = useState(null);
  const [meters, setMeters] = useState([]);

  const [compare, setCompare] = useState(false);
  const [compareMeter, setCompareMeter] = useState(null);
  // const [compareDate, setCompareDate] = useState([]);
  const [compareDateRange, setCompareDateRange] = useState(null);

  useEffect(() => {
    const today = new Date()
    setDateRange(defaultDateRange)
    setCompareMeter(meter.id) // defaults to same meter
    fetchMeters().then((res) => {
      setMeters(res)
    })
  }, [])
  
  useEffect(() => {
    if (dateRange) {
      fetchChartData(meter.id, dateRange).then((res) => {
        setChartData(res)
        if (compareDateRange) {
          fetchCompareData()
        }
      })
    }
  }, [dateRange, interval])

  useEffect(() => {
    if (compareDateRange) {
      fetchCompareData()
    }
  }, [compareDateRange, compareMeter])

  // useEffect(() => {
  //   if (chartData && compareDateRange) {
  //     console.log("SET_COMPARE_DATA")
  //     fetchCompareData()
  //   }
  // }, [chartData])


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      // get name of compared meter
      let meter2 = null
      if (payload[1]) {
        const m = meters.find(m => m.id === compareMeter)
        if (m) {
          meter2 = m.name || m.identifier
        }
      }

      return (
        <div className="custom-tooltip">
          {/* <p className="label energy">{`${label} : ${payload[0].value.toLocaleString()}`} kWh</p> */}
          <p className="label energy">
            {label}<br/>
            {meter?.name || meter.identifier}: {payload[0].value.toLocaleString()} kWh<br/>
            {payload[1] && (
               <span className="label compared">{meter2}: {payload[1]?.value.toLocaleString()} kWh</span>
            )}
          </p>

        </div>
      );
    }
    return null;
  };


  const handleSetDateRange = (range) => {
    setDateRange(range)
    if (compareDateRange) {
      setCompareDateRange(range)
    }
  }
  
  const handleCompareDateChange = (date) => {
    if (date) {
      const days = differenceInDays(dateRange[1], dateRange[0])
      const stopDate = new Date(date)
      stopDate.setDate(stopDate.getDate()+days)
      setCompareDateRange([
        date,
        stopDate
      ])
    }
    else {
      setCompareDateRange(null)
    }
  }

  const fetchCompareData = async () => {
    fetchChartData(compareMeter, compareDateRange).then((res) => {
      setChartData(prevState => {
        const newState = prevState;
        for (let i=0; i<newState.length; i++) {
          newState[i].compare = res[i]?.import
        }
        return [...newState]
      })
    })
  }

  const fetchMeters = async () => {
    try {
      const res = await axiosPrivate.get("/meters", {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      })
      return res.data
    }
    catch (err) {
      console.log(err)
    } 
  }

  const fetchChartData = async (meterId, date)=> {
    try {
      const from = date[0]
      const to = date[1]
      to.setDate(to.getDate()+1)
      const url = `/meters/${meterId}/metrics/energy?interval=${interval}&from=${format(from, 'yyyy-MM-dd')}&to=${format(to, 'yyyy-MM-dd')}`
      console.log(url)
      const res = await axiosPrivate.get(url, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      })
      return res.data
    }
    catch (err) {
      console.log(err)
      if (err.response.status === 401 || err.response.status === 403) {
          // redirect to login if unauthorized
          // navigate('/login', { state: { from: location, unauthorized: true }, replace: true });
      }
      else {
          // setError(err)
      }
    } 
  // setLoading(false)
  }

  const dateFormatter = t => {
    const time = new Date(t)
    let formatted = time;
    switch(interval) {
      case "hour":
        formatted = format(time, 'd/M HH:mm')
        break;
      case "day":
        formatted = format(time, 'd/M')
        break;
      case "month":
        formatted = format(time, 'MMM yy')
        break;
      case "year":
        formatted = format(time, 'yyyy')
        break;
    }
    return formatted
  }

  const CustomXAxisTick = ({ x, y, payload, width }) => {
    const date = dateFormatter(payload.value)
    if (payload?.value) {
      return (
        <Text
          width={width}
          x={x}
          y={y}
          textAnchor="middle"
          verticalAnchor="start"
        >{date}</Text>
      );
    }
    return null;
  };

  const renderMeterDropdownTitle = () => {
    if (compareMeter === meter.id) {
      return "Same Meter"
    }
    else {
      const m = meters.find(m => m.id === compareMeter)
      if (m) {
        return m.name || m.identifier
      }
    }
  }

  // console.log(chartData)

  return (
    <div className="chart">

      <div className="top">
        <div className="title">{title}</div>
        <div className="inputs">

          <div className="firstRow">
            <div className="interval">
              {intervalOptions.map(i => {
                const variant = i === interval ? "contained" : "outlined";
                return (
                  <Button 
                    key={i} 
                    variant={variant} 
                    onClick={()=>setInterval(i)}
                    size="small"
                  >
                    {i}
                  </Button>
                )
              })}
            </div>

            {/* 
            TODO
            Must handle a max date range for each interval types
            set different max amount dependongn on view
            allowedMaxDays(days: number) => boolean
            
            */}

            <div className="dateRange">
              <DateRangePicker 
                value={dateRange}
                onChange={handleSetDateRange}
                // character=" - "
                placement="bottomEnd"
                showOneCalendar
                showWeekNumbers
                isoWeek={true}
                ranges={predefinedRanges}
                // appearance="default" 
                // placeholder="Default" 
                style={{ width: 230 }} 
                format="yyyy-MM-dd"
                cleanable={false}
                shouldDisableDate={afterToday()}
              />
            </div>

          </div>

          <div className="secondRow">

            <div className="compareLabel">
              Compare Energy:
            </div>

            <div className="meterDropdown">
              <Dropdown 
                activeKey={compareMeter}
                title={renderMeterDropdownTitle()}
                trigger={['click', 'hover']}
                placement="bottomStart"
                onSelect={setCompareMeter}
                // toggleComponentClass="dropdown"
                menuStyle={{maxHeight: 250, overflowX : 'auto',fontSize: '14px'}}
              >
                <Dropdown.Item 
                  key={meter.id}
                  eventKey={meter.id}
                  active={compareMeter === meter.id}
                >
                  Same Meter
                </Dropdown.Item>
                <Dropdown.Separator />
                {meters.filter(m => m.id !== meter.id).sort((a,b) => a.identifier > b.identifier).map(m => (
                  <Dropdown.Item 
                    key={m.id}
                    eventKey={m.id}
                    active={compareMeter === m.id}
                  >
                    {m?.name || m.identifier}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>

            <DatePicker 
              value={compareDateRange && compareDateRange[0]}
              calendarDefaultDate={defaultDateRange[0]}
              onChange={handleCompareDateChange}
              oneTap
              showWeekNumbers 
              isoWeek={true}
              // cleanable={false}
              // format="yyyy-MM" 
              placeholder="Select From Date" 
              // shouldDisableDate={dateRange && after(dateRange[0])}
              shouldDisableDate={afterToday()}
              style={{ width: 230 }} 
            />

          {/* <DateRangePicker 
            value={compareDateRange}
            calendarDefaultDate={dateRange[0]}
            onChange={handleCompareDateChange}
            oneTap
            showWeekNumbers 
            isoWeek={true}
            // cleanable={false}
            // format="yyyy-MM" 
            placeholder="Compare Energy" 
            shouldDisableDate={dateRange && after(dateRange[0])}
            style={{ width: 230 }} 
          /> */}

        </div>

        </div>

      </div>

      <ResponsiveContainer width="100%" aspect={aspect}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 0, left: 25, bottom: 0 }}
        >
          <Legend 
            iconType="circle" 
            verticalAlign="bottom" 
            height={36}
            formatter={(label) => {
              if (label === "import") {
                return meter?.name || meter.identifier
              }
              if (label === "compare") {
                const meter2 = meters.find(m => m.id === compareMeter)
                return compareMeter === meter.id 
                  ? "Compared Energy"
                  : meter2?.name || meter2.identifier
              }
            }}
          />

          {/* <Legend 
            align="right"
            verticalAlign="bottom" 
            content={<CustomLegend external={true} />} 
          /> */}

          {/* <Legend content={renderLegend} /> */}

          {/* <Legend 
            iconSize={20}
            iconType="circle" 
            verticalAlign="bottom" 
            layout="horizontal"
            height={36}
            align="center"
            content={<CustomLegend />} 
            
          />  */}

          {/* <Tooltip 
            labelFormatter={value => dateFormatter(value)}
            formatter={(value, name) => value.toFixed(1) + ' kWh'}
            // cursor={{ fill: "transparent" }}
          /> */}
          <Tooltip content={<CustomTooltip />} />

          {/* <CartesianGrid strokeDasharray="3 3" /> */}

          <XAxis 
            dataKey="t" 
            fontFamily="sans-serif" 
            // tickSize dy="25" 
            // tickFormatter={dateFormatter}
            tick={<CustomXAxisTick width={50}/>}
          />
          <YAxis 
            label={{ value: 'kWh', angle: -90, position: 'left' }} 
          />

          <Bar
            dataKey="import"
            barSize={170}
            fontFamily="sans-serif"
            // fill="#3070aa"
            // minPointSize={1}
            fill="green"
            // label={<CustomizedLabel />}
          >
            {chartData?.length < 25 && (
              <LabelList dataKey="import" position="top" formatter={(value, name) => value.toFixed(1)}/>
            )}
            {/* {data.map((entry, index) => (
              <Cell fill={data[index].color} />
            ))} */}
          </Bar>

          {compareDateRange && (
            <Bar
              dataKey="compare"
              barSize={170}
              fontFamily="sans-serif"
              // fill="#3070aa"
              // minPointSize={1}
              fill="orange"
              // label={<CustomizedLabel />}
              >
              {chartData?.length < 25 && (
                <LabelList dataKey="compare" position="top" formatter={(value, name) => value.toFixed(1)}/>
              )}
            </Bar>
          )}


          {/* <Bar
            dataKey="compare"
          </Bar> */}


          {/* {chartData.comparison && (

          )} */}


        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default EnergyChart