import { createContext, useReducer, useEffect } from "react";
import AppReducer from "./AppReducer";

const INITIAL_STATE = {
  options: {
    admin: true
    // darkMode: JSON.parse(localStorage.getItem("darkMode")) || false,
  }
};

export const AppContext = createContext(INITIAL_STATE);

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, INITIAL_STATE);

  // useEffect (() => {
  //   localStorage.setItem("darkMode", JSON.stringify(state.darkMode))
  // }, [state.darkMode])

  return (
    <AppContext.Provider value={{ 
      options: state.options,
      dispatch 
    }}>
      {children}
    </AppContext.Provider>
  );
};