import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../hooks/useAuth"

// https://www.youtube.com/watch?v=oUZjO00NkhY

// redirect user to login page unless logged in
// could also redirect to unauthorized unless access

const RequireAuth = () => {
// const RequireAuth = ({ allowedRoles }) => {

    const { auth } = useAuth()
    const location = useLocation()

    return (
        auth?.user
            ? <Outlet />
            : <Navigate to="/login" state={{from: location}} replace />
    )

    // return (
    //     auth?.roles?.find(role => allowedRoles?.includes(role))
    //         ? <Outlet />
    //         : auth?.user
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );

}

export default RequireAuth