import format from 'date-fns/format'

import EnergyChart from '../../../components/EnergyChart/EnergyChart'
import PowerChart from '../../../components/PowerChart/PowerChart'
import './EnergyMeter.scss'
import MeterInfo from '../../../components/MeterInfo/MeterInfo'

const EnergyMeter = ({meter}) => {
  

  const renderLastSeen = () => {
    if (meter.lastSeen) {
      return format(new Date(parseInt(meter.lastSeen)), "yyyy-MM-dd HH:mm")
    }
    else {
      return "-"
    }
  }

  const modelImages = [
    "/qubino_3phase.png",
    "/qubino_1phase.png"
  ];

  return (
    <div className="meter">
      <div className="meterContainer">

        <div className="top">

          <div className="left">

            <MeterInfo meter={meter} />

          </div>

          <div className="right">
            {meter.id && (
              <EnergyChart meter={meter} aspect={4/1} title="Energy" />
            )}
          </div>
        </div>

        <div className="bottom">
          {meter.id && (
            <PowerChart meter={meter} aspect={6/1} title="Power" />
          )}
        </div>

      </div>
    </div>
  )

}

export default EnergyMeter