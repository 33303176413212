import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import useFetch from "../../hooks/useFetch"
import MyTable from "../mytable/MyTable"
import './metertable.scss'
import { orderBy } from 'natural-orderby';

const allColumns = [
  {
    headerName: "Meter",
    field: "identifier",
    renderCell: params => {
      return  <Link to={`/meters/${params.id}`}>{params.name || params.identifier}</Link>
    }
  },
  {
    headerName: "Section",
    field: "section_name",
    // width: 300,
    renderCell: params => {
      // return params?.Section?.name || "-"
      return  <Link to={`/sections/${params?.Section?.id}`}>{params?.Section?.name || "-"}</Link>
    }
  },
  {
    headerName: "Energy",
    field: "import",
    align: "right",
    renderCell: params => {
      return Number.isFinite(params?.status?.import) ? parseFloat(params?.status?.import).toLocaleString() : "-"
    }
  },
  // {
  //   headerName: "Power (W)",
  //   field: "power",
  //   align: "right",
  //   renderCell: params => {
  //     const power = params?.online ? params?.status?.power : "-"
  //     // return power;
  //     return Number.isFinite(params?.status?.power) ? parseFloat(params?.status?.power).toLocaleString() : "-"
  //   }
  // },
  {
    headerName: "Status",
    field: "status",
    renderCell: params => {
      const status = params?.online ? "online" : "offline"
      return (
        <span className={`cellWithStatus ${status}`}>{status}</span>
      )
    }
  },
]

const MeterTable = ({locationId, sectionId}) => {

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);

  let url = '';
  if (locationId) {
    url = `/locations/${locationId}/meters/data`
  }
  else if (sectionId) {
    url = `/sections/${sectionId}/meters`
  }

  const {data, loading, error } = useFetch(url)

  useEffect(() => {
    const sortedMeters = orderBy(
      data,
      [v => v?.Section?.name, v => v.name],
      ['asc']
    );
    setItems(sortedMeters)
    if (locationId) {
      setColumns(allColumns)
    }
    else if (sectionId) {
      setColumns(allColumns.filter(c => c.headerName !== "Section"))
    }
  }, [data])

  return (
      <MyTable columns={columns} rows={items} />
  )
}


export default MeterTable