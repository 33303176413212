import format from 'date-fns/format'
import './MeterInfo.scss'

const MeterInfo = ({meter}) => {
  
  const renderLastSeen = () => {
    if (meter.lastSeen) {
      return format(new Date(parseInt(meter.lastSeen)), "yyyy-MM-dd HH:mm")
    }
    else {
      return "-"
    }
  }

  const modelImages = [
    "/qubino_3phase.png",
    "/qubino_1phase.png",
    "",
    "/kamstrup_603.webp",
  ];

  return (
    <div className="meterInfo">

      <div className="header">
        <div className="title2">Meter Info</div>
      </div>

      <div className="content">

        <div className="iconDiv">
          <img 
            src={modelImages[meter.meterTypeId-1]}
            alt=""
            className="itemImg"
          />
        </div>

          <div className="details">

            <div className="detailLabels">
              <div className="itemKey">Name</div>
              <div className="itemKey">Type</div>
              <div className="itemKey">Vendor</div>
              <div className="itemKey">Model</div>
              <div className="itemKey">Location</div>
              <div className="itemKey">Section</div>
              {/* <div className="itemKey">Last Seen</div> */}
            </div>

            <div className="detailValues">
              <div className="itemValue">{meter.name || meter.identifier}</div>
              <div className="itemValue">{meter?.MeterType?.type}</div>
              <div className="itemValue">{meter?.MeterType?.vendor}</div>
              <div className="itemValue">{meter?.MeterType?.model}</div>
              <div className="itemValue">{meter?.Section?.Location?.name}</div>
              <div className="itemValue">{meter?.Section?.name}</div>
            </div>
          </div>

          <div className="details">

            <div className="detailLabels">
              <div className="itemKey">Status</div>
              <div className="itemKey">Last Seen</div>
              <div className="itemKey">Energy</div>
              { Number.isFinite(meter?.status?.export) && <div className="itemKey">Export</div> }
              { meter.MeterType.type === "HeatMeter" && <div className="itemKey">Power</div> }
            </div>

            <div className="detailValues">
              <div className="itemValue">{meter?.online ? "Online" : "Offline"}</div>
              <div className="itemValue">{renderLastSeen()}</div>
              <div className="itemValue">{Number.isFinite(meter?.status?.import) ? meter?.status?.import.toLocaleString() + " kWh" : "-" }</div>
              { Number.isFinite(meter?.status?.export) && 
                <div className="itemValue">{meter?.status?.export.toLocaleString()} kWh</div>
              }
              { meter.MeterType.type === "HeatMeter" && 
                <div className="itemValue">{(meter?.online && Number.isFinite(meter?.status?.power)) ? meter?.status?.power.toLocaleString() + " W" : "-" }</div>
              }
            </div>
          </div>

{/* 
              <div className="itemDetail">
                <span className="itemKey">Current Power:</span>
                <span className="itemValue">{(meter?.online && Number.isFinite(meter?.status?.power)) ? meter?.status?.power + " W" : "-" }</span>
              </div>
            </div> */}

      </div>
    </div>
  )
}

export default MeterInfo;