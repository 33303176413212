import { Routes, Route, Navigate } from "react-router-dom";

import Missing from "./pages/missing/Missing"
import Layout from "./components/layout/Layout"
import RequireAuth from "./components/RequireAuth"

import Dashboard from "./pages/dashboard/Dashboard"
// import Home from "./pages/home/Home"
import Login from "./pages/login/Login"
import List from "./pages/list/List"
import Single from "./pages/single/Single"
import New from "./pages/new/New"

import Meter from "./pages/Meter/Meter"

import { userInputs, productInputs } from './formSource'
import './styles/dark.scss'
import MeterList from "./pages/MeterList/MeterList";
// import Locations from "./pages/locations/Locations";
import Section from "./pages/section/Section";

function App() {
  
  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        <Route path="login" element={<Login />} />

        {/* protected routes */}
        <Route element={<RequireAuth />}>

        <Route index element={<Dashboard />} />

          <Route path="meters">
            <Route index element={<MeterList />}  />
          </Route>
          <Route path="meters">
            <Route path=":meterId" element={<Meter />} />
          </Route>

          <Route path="sections">
            <Route path=":sectioId" element={<Section />} />
          </Route>




          {/* <Route path="energy">
            <Route index element={<Energy />}  />
            <Route path=":meterId" element={<Meter />} />
          </Route>

          <Route path="users">
            <Route index element={<List />} />
            <Route path=":userId" element={<Single />} />
            <Route path="new" element={<New inputs={userInputs} title="Add New User" />} />
          </Route>

          <Route path="products">
            <Route index element={<List />} />
            <Route path=":productId" element={<Single />} />
            <Route path="new" element={<New inputs={productInputs} title="Add New Product" />} />
          </Route> */}

        </Route>

      </Route>

      {/* catch all */}
      <Route path="*" elememt={<Missing />} />

    </Routes>
  );
}

export default App;
