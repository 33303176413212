import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthProvider'
import { AppContextProvider } from './context/AppContext'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppContextProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AppContextProvider>
        </LocalizationProvider>
      </AuthProvider>
    </BrowserRouter>
  // </React.StrictMode>
);
