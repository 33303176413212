
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import format from 'date-fns/format'

import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import 'rsuite/dist/rsuite.min.css';

import useAxiosPrivate from "../../hooks/useAxiosPrivate"
// import useFetch from "../../hooks/useFetch"
import "./PowerChart.scss"

import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    Area,
    LineChart,
    Line,
    ReferenceLine,
    XAxis,
    YAxis,
    Cell,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList
} from "recharts";

const { combine, allowedMaxDays, afterToday } = DateRangePicker;

// todo: add support to get average values on bigger intervals


const predefinedRanges = [
  // {
  //   label: 'Today',
  //   value: [new Date(), new Date()],
  //   placement: 'left'
  // },
  // {
  //   label: 'Yesterday',
  //   value: [addDays(new Date(), -1), addDays(new Date(), -1)],
  //   placement: 'left'
  // },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  // {
  //   label: 'All time',
  //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
  //   placement: 'left'
  // },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];


const now = new Date()
const defaultDateInterval = "day"
const defaultDateRange = [
  subDays(new Date(), 29),
  new Date()
]
// new Date(now.setDate(now.getDate()-30)),
defaultDateRange[0].setHours(0, 0, 0);
defaultDateRange[1].setHours(0, 0, 0);

const PowerChart = ({meter, aspect, title}) => {

  const axiosPrivate = useAxiosPrivate()

  const [interval, setInterval] = useState("month");
  const [customDateSet, setCustomDateSet] = useState(false);

  const [dateRange, setDateRange] = useState(defaultDateRange)

  const [multiplePhases, setMultiplePhases] = useState(false);
  const [chartData, setChartData] = useState([]);

  // const { data, loading, error, reFetch } = useFetch(`/meters/${meter.id}/metrics/power?interval=${interval}&from=${format(dateRange[0], 'yyyy-MM-dd HH:mm')}&to=${format(dateRange[1], 'yyyy-MM-dd HH:mm')}`)

  const gradientOffset = () => {
    if (chartData) {
      const dataMax = Math.max(...chartData.map(i => i.power));
      const dataMin = Math.min(...chartData.map(i => i.power));
      if (dataMax <= 0) {
        return 0;
      } 
      else if (dataMin >= 0) {
        return 1;
      } 
      else {
        return dataMax / (dataMax - dataMin);
      }
    }
  };

  useEffect(() => {
    if (dateRange) {
      fetchChartData(meter.id, dateRange).then((res) => {
        setChartData(res)
      })
    }

    // console.log(data)
    // if (data.find(m => m.power_l1 || m.power_l1 || m.power_l1)) {
    //   setMultiplePhases(true)
    // }
    // console.log("POWER")
    // console.log(data)
    // setChartData(data)
  }, [dateRange, interval]);


  const fetchChartData = async (meterId, date)=> {
    try {
      const from = date[0]
      const to = date[1]
      to.setDate(to.getDate()+1)
      const url = `/meters/${meter.id}/metrics/power?interval=${interval}&from=${format(from, 'yyyy-MM-dd HH:mm')}&to=${format(to, 'yyyy-MM-dd HH:mm')}`
      const res = await axiosPrivate.get(url, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      })
      return res.data
    }
    catch (err) {
      console.log(err)
      if (err.response.status === 401 || err.response.status === 403) {
          // redirect to login if unauthorized
          // navigate('/login', { state: { from: location, unauthorized: true }, replace: true });
      }
      else {
          // setError(err)
      }
    } 
  // setLoading(false)
  }



  const off = gradientOffset();

  return (
    <div className="chart">
      <div className="top">
        <div className="title">{title}</div>
        <div className="inputs">
          <DateRangePicker 
            value={dateRange}
            onChange={setDateRange}
            // character=" - "
            placement="bottomEnd"
            showOneCalendar
            showWeekNumbers
            ranges={predefinedRanges}
            // appearance="default" 
            // placeholder="Default" 
            style={{ width: 230 }} 
            format="yyyy-MM-dd"
            cleanable={false}
            shouldDisableDate={afterToday()}
          />
        </div>

      </div>

      <ResponsiveContainer width="100%" aspect={aspect}>


{/* <LineChart width={730} height={250} data={data}
  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="name" />
  <YAxis />
  <Tooltip />
  <Legend />
  <Line type="monotone" dataKey="pv" stroke="#8884d8" />
  <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
</LineChart> */}
        <ComposedChart
          data={chartData}
          margin={{ top: 10, right: 0, left: 25, bottom: 0 }}
        >
            <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="green" />
                <stop offset={off} stopColor="#ffc100" />
                {/* <stop offset={off} stopColor="#addd43"  /> */}
                {/* <stop offset={off} stopColor="#3070aa"  /> */}
                </linearGradient>
            </defs>
            <XAxis 
                dataKey="t" 
                // fontFamily="sans-serif" 
                tickSize dy="5" 
                tickFormatter={t => format(new Date(t), 'd/M')}
                // domain={["dataMin", "dataMax"]}
                type={"number"}
                domain={['auto', 'auto']}
                // tickFormatter={dateFormatter}
                />
            <YAxis label={{ value: 'W', angle: -90, position: 'left' }} />
            <Tooltip 
                labelFormatter={t => format(new Date(t), 'd/M HH:mm')}
                formatter={(value, name) => value.toFixed(1) + ' W'}
                // cursor={{ fill: "transparent" }}
            />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <ReferenceLine y={0} stroke="lightgrey" strokeDasharray="3 3" />

            <Area
              name="Power"
              type="monotone"
              dataKey="power"
              connectNulls={true}
              stroke="url(#gradient)"
              fill="url(#gradient)"
              dot={false}
              activeDot={false}
            >
                {chartData.length < 25 && (
                <LabelList dataKey="import" position="top" />
                )}
                {/* {data.map((entry, index) => (
                <Cell fill={data[index].color} />
                ))} */}
              </Area>
              { multiplePhases && (
                <>
                  <Line name="Power L1" connectNulls={true} type="monotone" dataKey="power_l1" dot={false} stroke="saddlebrown" />
                  <Line name="Power L2" connectNulls={true} type="monotone" dataKey="power_l2" dot={false} stroke="black" />
                  <Line name="Power L3" connectNulls={true} type="monotone" dataKey="power_l3" dot={false} stroke="grey" />
                  <Legend verticalAlign="top" height={0} formatter={(value, name) => value.replace("_", " ")}/>
                </>
              )}

            </ComposedChart>
        </ResponsiveContainer>
    </div>
  )
}

export default PowerChart