import { useRef, useState, useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import axios from "../../api/axios"
import "./login.scss"

const Login = () => {

  const { setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"
  const unauthorized = location.state?.unauthorized

  const emailRef = useRef()
  const errorRef = useRef()

  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [expired, hideExpired] = useState(unauthorized)
  const [errorMsg, setErrorMsg] = useState(false);

  // const [validEmail, setValidEmail] = useState(false);
  // const [validPwd, setValidPwd] = useState(false);

  // focus on email input
  // no input -> only on component load
  useEffect(() => {
    emailRef.current.focus()
  }, []) 

  // clear error message on field change
  useEffect(() => {
    setErrorMsg('')
  }, [email, password]) 

  const [ credentials, setCredentials ] = useState({
    email: undefined,
    password: undefined
  })

  const handleChange = (e) => {
    setCredentials(prev=>({...prev, [e.target.id]:e.target.value}))
  }

  const handleSubmit = async (e) => {
    setErrorMsg('')
    hideExpired()
    e.preventDefault()
    try {
      const res = await axios.post("/auth/login", credentials, { 
        headers: {'Content-Type': 'application/json'},
        withCredentials: true 
      })
      const user = res.data?.user
      const accessToken = res?.data?.accessToken;
      const roles = res?.data?.roles;
      setAuth({user, roles, accessToken})
      setEmail('')
      setPassword('')
      navigate(from, { replace: true })
    }
    catch(err) {
      // console.log(err)
      // if (!err?.response) {
      //   // todo: show this error
      //   setErrorMsg("No Server Response")
      // }
      if (err.response?.status === 400) {
        setErrorMsg("Missing Email or Password")
      }
      // else if (err.response?.status === 401) {
      //   setErrorMsg("Unauthorized")
      // }
      else {
        setErrorMsg("Login Failed")
      }
      // errorRef.current.focus() // for screen readers
    }
  }

  return (
    <section className="login">
      <div className="wrapper">
        <h1 className="title">Energyportal</h1>
        <form className="loginForm" onSubmit={handleSubmit}>
          {expired && <div className="errorMsg">Your session has expired</div>}
          <div className="formInput">
            <label htmlFor="email">Email</label>
            <input 
              type="text" 
              id="email"
              // placeholder="email" 
              ref={emailRef}
              autoComplete="off"
              onChange={handleChange}
              // onChange={(e) => setUser(e.target.value)}
              // value={user}
              required
            />
          </div>
          <div className="formInput">
            <label htmlFor="password">Password</label>
            <input 
              type="password"
              id="password"
              // placeholder="password"
              onChange={handleChange}
              // onChange={(e) => setUser(e.target.value)}
              // value={user}
              required
            />
          </div>
          <button 
            className="loginButton" 
            // disabled={loading}
            // disabled={!validName || !validPwd ? true : false}
          >
            Login
          </button>
          {errorMsg && <div ref={errorRef} className="errorMsg">{errorMsg}</div>}
        </form>
      </div>
    </section>
  )
}

export default Login