import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import format from "date-fns/format";

import useFetch from "../../hooks/useFetch";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Navbar from "../../components/navbar/Navbar";
import MyTable from "../../components/mytable/MyTable";
import MeterTable from "../../components/metertable/MeterTable";
import "./Section.scss";
import { Breadcrumb } from "rsuite";



/*

Upptill
=======
* SectionInfo box
  *  namn/adress mm.

* SectionData box



Nedtill
=======
* En tabell med mätare på denna sektion

*/

let startDate = new Date();
startDate.setMonth(startDate.getMonth() - 12);
startDate.setDate(1);
startDate.setHours(0, 0, 0, 0);

let lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

let endDate = new Date();
endDate.setMonth(endDate.getMonth() + 1);
endDate.setDate(1);
endDate.setHours(0, 0, 0, 0);

const Location = () => {
  const axiosPrivate = useAxiosPrivate();
  const loc = useLocation();
  const sectionId = loc.pathname.split("/")[2];

  const { data, loading, error } = useFetch(`/sections/${sectionId}`);
  const [isLoaded, setIsLoaded] = useState(false);
  const [section, setSection] = useState(null);
  // const [meterData, setMeterData] = useState(null);
  const [energyData, setEnergyData] = useState([]);
  const [energyCurrentMonth, setEnergyCurrentMonth] = useState(null);
  const [energyLastMonth, setEnergyLastMonth] = useState(null);


  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!isLoaded) return;
    setSection(data);
    fetchEnergyData().then((res) => {
      setEnergyData(res);
      const curr = res.find(e => e.t === format(Date.now(), "yyyy-MM"))
      if (curr) {
        setEnergyCurrentMonth(curr.import);
      }
      const last = res.find(e => e.t === format(lastMonth, "yyyy-MM"))
      if (last) {
        setEnergyLastMonth(last.import);
      }
    });
  }, [data]);

  async function fetchEnergyData() {
    try {
      const res = await axiosPrivate.get(`/sections/${sectionId}/energy`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      return res.data;
    } catch (err) {}
  }


  return (
    <>
      <Navbar />
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item className="breadcrumb-item" href="/meters">Meters</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">{section?.Location?.Customer?.name}</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item">{section?.Location?.name}</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item"active>{section?.name}</Breadcrumb.Item>
        </Breadcrumb>

      <div className="sectionContainer">
        <div className="topContainer">
          <div className="left">
            <h2>Section Info</h2>

            <table className="infotable">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{section?.name}</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>{section?.Location?.Customer?.name}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{section?.Location?.name}</td>
                </tr>
                <tr>
                  <td>Adress</td>
                  <td>{section?.address}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="right">
            <h2>Energy Consumption</h2>
            <table className="infotable">
              <tbody>
                <tr>
                  <td>Current Month</td>
                  <td>
                    {energyCurrentMonth && `${energyCurrentMonth.toFixed(1)} kWh`}{" "}
                  </td>
                </tr>
                <tr>
                  <td>Last Month</td>
                  <td>
                    {energyLastMonth && `${energyLastMonth.toFixed(1)} kWh`}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="bottomContainer">
          {section && <MeterTable sectionId={section.id} />}
        </div>
      </div>
    </>
  );
};

export default Location;
