import { useNavigate, Link } from 'react-router-dom'
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import LogoutIcon from '@mui/icons-material/Logout';

import "./navbar.scss"

// import { useContext } from 'react'
// import { AuthContext } from '../../context/AuthContext.js.old'


const Navbar = () => {

  const { setAuth } = useAuth()
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate()

  const handleLogout = async () => {
    try {
      const res = await axiosPrivate.get("/auth/logout", {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
      })
    }
    catch(err) {
    }
    setAuth({});
    navigate('/login');
  }


  return (
    <div className="navbarContainer">
      <div className="navbarLeft">
        <Link to="/" style={{textDecoration: "none"}}>
          <span className="logo">Energyportal</span>
        </Link>
      </div>
      <div className="navbarCenter">
        <div className="navbarLinks">

          <Link to="/meters" style={{textDecoration: "none"}}>
            <div className="linkItem">
              <ElectricBoltIcon className="icon" />
              <span className="link">Meters</span>
            </div>
          </Link>

        </div>
      </div>

      <div className="navbarRight">

        {/* <div className="icons">
          <div className="item">
            <NotificationsOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
        </div> */}

        {/* {user ? user.email : ( */}
        {/* {user && (
          <div className="icons">
          <div className="item">
            <div className="counter">{user.email}</div>
          </div>
        </div>
        )} */}
        <div className="navbarLinks">
            <Link to="#" onClick={() => handleLogout()} style={{textDecoration: "none"}}> 
              <div className="linkItem">
                <LogoutIcon className="icon" />
                <span className="link">Logout</span>
              </div>
            </Link>
        </div>



      </div>

    </div>
  )

//   <div className="wrapper">
//   <div className="search">
//     <input type="text" placeholder="Search..." />
//     <SearchOutlinedIcon className="icon" />
//   </div>
//   <Link to="/users" style={{textDecoration: "none"}}>
//   <li>
//     <PersonOutlineOutlinedIcon className="icon" />
//     <span>Users</span>
//   </li>
//   </Link>


//   <div className="items">
//     <div className="item">
//       <LanguageOutlinedIcon className="icon" />
//       English
//     </div>
//     <div className="item">
//       <NotificationsOutlinedIcon className="icon" />
//       <div className="counter">2</div>
//     </div>
//     <div className="item">
//       <img
//         src="https://images.pexels.com/photos/13131586/pexels-photo-13131586.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
//         alt="JR"
//         className="avatar"
//       />
//     </div>
//   </div>
// </div>

}

export default Navbar